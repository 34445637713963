const DocMenuConfig = [
    {
        pages: [
            {
                heading: "Tableau de board",
                route: "/dashboard",
                svgIcon: "media/icons/duotune/art/art002.svg",
                fontIcon: "bi-app-indicator",
            },
        ],
    },
    {
        heading: "Master Data",
        pages: [
            {
                sectionTitle: "Référentiels",
                route: "",
                svgIcon: "media/icons/duotune/general/gen008.svg",
                fontIcon: "bi-filter",
                sub: [
                    {
                        heading: "Gestion des Structures",
                        route: "/referencials/structures",
                    },
                    {
                        heading: "Gestion des Infrastructures",
                        route: "/referencials/infrastructures",
                    },
                    {
                        heading: "Gestion des Ateliers",
                        route: "/referencials/parks",
                    },
                    {
                        sectionTitle: "Gestion des Entreprises Externes",
                        route: "/referencials/suppliers",
                        sub: [
                            {
                                heading: "Perstataires ou Fournisseurs",
                                route: "/referencials/suppliers",
                            },
                            {
                                heading: "Intra-Entreprise",
                                route: "/referencials/suppliers/intra",
                            }
                        ]
                    },
                    {
                        sectionTitle: "Gestion de stock",
                        route: "/referencials/stock",
                        sub: [
                            {
                                heading: "Etat de stock",
                                route: "/referencials/stock",
                            },
                            {
                                heading: "Gestion des magasins",
                                route: "/referencials/warehouses",
                            },
                            {
                                heading: "Gestion des catégories produits",
                                route: "/referencials/products/categories",
                            },
                            {
                                heading: "Gestion des attributs produits",
                                route: "/referencials/products/attributes",
                            },
                            {
                                heading: "Gestion des produits",
                                route: "/referencials/products",
                            },
                            {
                                heading: "Gestion des entrées de stock",
                                route: "/referencials/products/inputs",
                            },
                            {
                                heading: "Gestion des sorties de stock",
                                route: "/referencials/products/outputs",
                            },
                        ]
                    },
                    {
                        sectionTitle: "Gestion des Employés",
                        route: "/referencials/employees",
                        sub: [
                            {
                                heading: "Employés Internes",
                                route: "/referencials/employees",
                            },
                            {
                                heading: "Employés Intra-Entreprise",
                                route: "/referencials/employees/intra",
                            },
                            {
                                heading: "Employés de Prestataires",
                                route: "/referencials/employees/supplier",
                            }
                        ]
                    },
                    {
                        sectionTitle: "Gestion des Comptes Analytics",
                        route: "/referencials/analytic-accounts",
                        sub: [
                            {
                                heading: "Internes",
                                route: "/referencials/analytic-accounts",
                            },
                            {
                                heading: "Intra-Entreprise",
                                route: "/referencials/analytic-accounts/intra",
                            }
                        ]
                    },
                ]
            },
            {
                sectionTitle: "Contrats",
                route: "",
                svgIcon: "media/icons/duotune/ecommerce/ecm006.svg",
                fontIcon: "bi-file",
                sub: [
                    {
                        heading: "Contrat",
                        route: "/contracts/contract/",
                    }
                ]
            },
        ]
    },
    {
        heading: "Catalogue services",
        pages: [
            {
                sectionTitle: "Flotte",
                route: "",
                svgIcon: "media/icons/duotune/ecommerce/ecm006.svg",
                fontIcon: "bi-truck",
                sub: [
                    {
                        heading: "Véhicules",
                        route: "/services/fleet/vehicles",
                    }
                ]
            },
            {
                sectionTitle: "Requête",
                route: "",
                svgIcon: "media/icons/duotune/ecommerce/request1.svg",
                fontIcon: "bi-envelope-paper",
                sub: [
                    {
                        heading: "Maintenance",
                        route: "/services/request/maintenance",
                    },
                    {
                        heading: "Transport",
                        route: "/services/request/transport",
                    }
                ]
            }
        ]
    },
    {
        heading: "Configuration",
        pages: [
            {
                heading: "Listes déroulantes",
                route: "/settings/dropdowns",
                svgIcon: "media/icons/duotune/text/txt009.svg",
                fontIcon: "bi-list-ul",
            },
            {
                heading: "Rôles et Permissions",
                route: "/settings/roles",
                svgIcon: "media/icons/duotune/general/gen029.svg",
                fontIcon: "bi-star-fill",
            }
        ]
    },
];

export default DocMenuConfig;
