
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "kt-user-menu",
    data() {
        return {
            darkMode: false,
            mode: "Dark"
        }
    },
    created() {
        this.darkMode = localStorage.getItem('darkMode') === 'true';
        if (this.darkMode) this.mode = "Light"
        else this.mode = "Dark"
    },
    components: {},
    setup() {
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();

        i18n.locale.value = localStorage.getItem("lang")
            ? (localStorage.getItem("lang") as string)
            : "fr";

        store
            .dispatch(Actions.SET_LOCALE, i18n.locale.value);

        const countries = {
            ar: {
                flag: "media/flags/algeria.svg",
                name: "Arabic",
            },
            fr: {
                flag: "media/flags/france.svg",
                name: "French",
            },
            en: {
                flag: "media/flags/united-states.svg",
                name: "English",
            },
        };

        const signOut = () => {
            store
                .dispatch(Actions.LOGOUT)
                .then(() => router.push({ name: "sign-in" }));
        };

        const setLang = (lang) => {
            localStorage.setItem("lang", lang);
            i18n.locale.value = lang;

            store.dispatch(Actions.SET_LOCALE, i18n.locale.value);
        };

        const currentLanguage = (lang) => {
            return i18n.locale.value === lang;
        };

        const currentLangugeLocale = computed(() => {
            return countries[i18n.locale.value];
        });

        return {
            signOut,
            setLang,
            currentLanguage,
            currentLangugeLocale,
            countries,
        };
    },
    methods: {
        changeMode() {
            this.darkMode = !this.darkMode
            localStorage.setItem('darkMode', this.darkMode.toString())

            location.reload()
        }
    },
});
