
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n/index";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";

export default defineComponent({
    name: "KTMenu",
    components: {},
    setup() {
        const { t, te } = useI18n();
        const route = useRoute();

        const hasActiveChildren = (match) => {
            return route.path.indexOf(match) !== -1;
        };

        const translate = (text) => {
            if (te(text)) {
                return t(text);
            } else {
                return text;
            }
        };

        return {
            hasActiveChildren,
            headerMenuIcons,
            MainMenuConfig,
            translate,
            version,
        };
    },
});
